<template>
  <section class="section-home">
    <article class="article">
      <div class="article-image">
        <img src="@/assets/images/about.jpg" alt="About" loading="lazy" >
      </div>
      <div class="article-content">
        <h2 class="headline">Hi, I'm Dominik</h2>
        <div class="post-details">
          <span class="post-date">Zagreb, Croatia</span>
        </div>
        <div class="content">
          <p><strong>Front-end developer</strong>, <strong>UX/UI designer</strong> based in <i>Zagreb, Croatia</i> with over 10 years commercial experience creating successful websites.</p>
          <p>I have worked on a multitude of web, mobile and print based projects for a range of clients providing <strong>Development</strong> (HTML, CSS, Sass, JS, VueJS, PHP and Wordpress), eCommerce Solutions, Corporate Branding and <strong>UX/UI Design</strong> (Figma, Adobe XD).</p>
          <p>Taking advantage of OCD and my eye for details provides me making better web, one that is beautiful, lightweight, fast, smooth, easy to use and last but not least → <b>frustration-free experience</b>.</p>
          <p>Got any questions? <router-link to="/contact">Contact me</router-link>.</p>
        </div>
      </div>
    </article>
  </section>
</template>

<script>
/* Mixins */
import { Default } from '@/mixins/default'
import { Meta } from '@/mixins/meta'


/* Logic */
export default {
  name: 'HomeView',
  mixins: [Default, Meta],
  components: {
  },
  data: function () {
    return {
      item: [],
      data: [],
    }
  },
  methods: {
  },
  mounted: function () {
    this.initialize();
  },
  created: function () {
  },
}
</script>
