import { useMeta } from 'vue-meta';

export const Meta = {
    data: function () {
      return {
          meta: {
              title: '',
              author: '',
              keywords: '',
              robots: '',
              description: '',
              facebook_title: '',
              facebook_description: '',
              locality: '',
              image: '',
              url: '',
          }
      }
    },
    methods: {
        setMeta: function () {
            useMeta({
                title: (this.meta.title ? this.meta.title : this.$route.name) +' | avenuez',
                htmlAttrs: {
                    lang:  this.meta.locality ? this.meta.locality : 'en',
                    amp: false
                },
                meta: [
                    { name: 'author', content: this.meta.author ? this.meta.author : 'zugakeys' },
                    { name: 'keywords', content: this.meta.keywords ? this.meta.keywords : 'frontend, developer, freelance, ux, ui, designer, wordpress, theme, creator, templates, vuejs' },
                    { name: 'robots', content: this.meta.robots ? this.meta.robots : 'index,follow' },
                    { name: 'description', content: this.meta.description ? this.meta.description : 'Frontend developer, UX/UI designer and Wordpress lover' },
                    { itemprop: 'name', content: (this.meta.title ? this.meta.title : this.$route.name) +' | avenuez' },
                    { itemprop: 'description', content: this.meta.description ? this.meta.description : 'Frontend developer, UX/UI designer and Wordpress lover' },
                    { itemprop: 'image', content: this.meta.image ? this.meta.image : window.location.origin + require('@/assets/images/avenuez.jpg') }
                ],
                og: {
                    title: (this.meta.title ? this.meta.title : this.$route.name) +' | avenuez',
                    description: this.meta.description ? this.meta.description : 'Frontend developer, UX/UI designer and Wordpress lover',
                    site_name: (this.meta.title ? this.meta.title : this.$route.name) +' | avenuez',
                    type: 'website',
                    url:  window.location.href,
                    image: this.meta.image ? this.meta.image : window.location.origin + require('@/assets/images/avenuez.jpg') },
                twitter: {
                    card: 'summary',
                    site: this.meta.url ? this.meta.url : window.location.href,
                    title: (this.meta.title ? this.meta.title : this.$route.name) +' | avenuez',
                    description: this.meta.description ? this.meta.description : 'Frontend developer, UX/UI designer and Wordpress lover'
                }
            })
        },
    },
    created() {
        this.setMeta()
    },
}
