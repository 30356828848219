import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from "secure-ls";

const ls = new SecureLS({
  isCompression: false
});

const Store = createStore({
  plugins: [
    createPersistedState({
      key: 'avenuez',
      //hashirani local storage
/*      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      }*/
    }),
  ],
  state: {
    loading: false,
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
});

export default Store;
