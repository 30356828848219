<template>
  <div class="partial-footer">
    <footer id="footer" class="footer">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-9 offset-md-3">
            <div class="footer-inner">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <h3 class="title">About</h3>
                  <p>
                    Basically I'm a musician, keyboard player. <b>But!</b> COVID-19 made me become a <u>full-time</u> front-end web developer.
                  </p>
                </div>
                <div class="col-12 col-sm-6">
                  <h3 class="title">Contact</h3>
                  <ul>
                    <li><b>Email:</b> <a href="mailto:info@avenuez.me">info@avenuez.me</a></li>
                    <li><b>Location:</b> <a href="https://goo.gl/maps/nxNQPyTDKMtrVwNP9" target="_blank">Trg Mladosti 14, 10290 Zaprešić, HR</a></li>
                  </ul>
                </div>
              </div>
            </div>
            <Copyright/>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import Copyright from "@/components/Partials/Copyright";


  export default {
    name: "Footer",
    components: {
      Copyright
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
.footer-inner {
  margin-top: 80px;
  border-top: 1px solid #ababab;
  padding-top: 40px;
  margin-left: 80px;
  font-size: 12px;

  @media screen and (max-width: 767px) {
    margin-left: 0;
    margin-top: 45px;
  }

  @media screen and (max-width: 575px) {
    text-align: center;
  }

  h3 {
    &.title {
      font-size: 16px;
      font-weight: 900;
    }
  }

  p {
    line-height: 2;

    @media screen and (max-width: 575px) {
      padding: 0 30px;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    line-height: 2;
  }
}
.partial-copyright {
  text-align: center;

  @media screen and (max-width: 767px) {
    display: initial;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
}
</style>
