<template>
  <div class="partial-cookie-consent" :class="containerPosition" v-if="isOpen">
    <div class="content">
      <div class="message">{{ message }} <router-link :to="`/${readMoreLink}`">{{ readMoreText }}</router-link>.</div>
      <div class="btn-wrapper">
        <button class="button-deny" @click="deny">{{ buttonTextDeny }}</button>
        <button class="button-accept" @click="accept">{{ buttonTextAccept }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieConsent",
  props: {
    buttonTextAccept: {
      type: String,
      default: 'Allow'
    },
    buttonTextDeny: {
      type: String,
      default: 'Don\'t allow'
    },
    message: {
      type: String,
      default: 'Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies. Find out how we use cookies.'
    },
    position: {
      type: String,
      default: 'bottom'
    },
    readMoreText: {
      type: String,
      default: 'Read More'
    },
    readMoreLink: {
      type: String,
      default: 'privacy-policy'
    }
  },
  data: function () {
    return {
      isOpen: false
    }
  },
  computed: {
    containerPosition() {
      return 'cookie-position-bottom';
    }
  },
  created () {
    if (!this.getGDPR() === true) {
      this.isOpen = true;
    }
  },
  methods: {
    getGDPR() {
      return localStorage.getItem('GDPR:accepted', true);
    },
    accept() {
      this.isOpen = false;
      localStorage.setItem('GDPR:accepted', true)
    },
    deny() {
      this.isOpen = false;
      localStorage.setItem('GDPR:accepted', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.partial-cookie-consent {
  position: fixed;
  background-color: #f5f7f8;
  z-index: 9999;
  width: 100%;
  padding: 20px 40px;
  font-size: 14px;

  @media screen and (max-width: 767px) {
    border-top: 1px solid #ababab;
  }

  .content {
    margin: 0 auto;
    padding: 0 12px;
    display: flex;
    max-width: 935px;

    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0;
      flex-direction: column;
    }

    .message {
      display: inline-block;
      padding-right: 20px;
      flex: 1;

      @media screen and (max-width: 767px) {
        padding-right: 0;
        padding-bottom: 15px;
      }
    }

    .btn-wrapper {
      align-self: center;

      .button-accept {
        display: inline-block;
        min-width: 100px;
        padding: 9px 20px;
        background-color: #000000;
        color: #ffffff;
        border: none;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 600;
        text-align: center;

        &:hover {
          color: #808080;
        }
      }
      .button-deny {
        display: inline-block;
        min-width: 100px;
        padding: 8px 20px;
        background-color: transparent;
        color: #000000;
        border: 1px solid #000000;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        font-weight: 600;
        text-align: center;
        margin-right: 5px;

        &:hover {
          background-color: #000000;
          color: #ffffff;
        }
      }
    }
  }
}

.cookie-position-bottom {
  bottom: 0;
  left: 0;
}
</style>
