<template>
  <metainfo>
    <template v-slot:title="{ content }">{{ content ? `${content}` : `avenuez` }}</template>
  </metainfo>
  <!-- Cookie consent -->
  <CookieConsent/>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-4 col-12">
        <div class="navigation-wrap">
          <div class="table">
            <div class="table-center">
              <!-- Navigation -->
              <Navigation/>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-9 col-md-8 col-12">
        <div class="page-content">
          <!-- views -->
          <router-view v-slot="{ Component }" >
            <transition name="fade" mode="out-in">
              <component :is="Component" />
            </transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
  <!-- Navigation -->
  <transition name="fade" mode="out-in">
    <Footer :key="$route.fullPath" />
  </transition>
</template>
<script>
// Components
import Loading from '@/components/Partials/Loading';
import Navigation from '@/components/Partials/Navigation';
import Footer from "@/components/Partials/Footer";
import CookieConsent from "@/components/Partials/CookieConsent";

// Mixins
import { Default } from '@/mixins/default'
import { useToast } from "vue-toastification";
const toast = useToast()

export default {
  name: 'Index',
  mixins: [Default],
  components: {
    Loading,
    Navigation,
    Footer,
    CookieConsent
  },
  data() {
    return {
    }
  },
  methods: {

  },
}
</script>
<style lang="scss">
  // Bootstrap
  @import "~bootstrap";

  // Style
  @import "@/assets/css/style.scss";

/* scrollbar content move prevent */
  html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: transform 0.32s ease-out 0s, opacity 0.3s ease-out 0s;
  }

  .fade-enter-from {
    opacity: 0;
    -webkit-transform: rotateX(0deg) translateX(0px) translateY(-10px);
    -moz-transform: rotateX(0deg) translateX(0px) translateY(-10px);
    -ms-transform: rotateX(0deg) translateX(0px) translateY(-10px);
    -o-transform: rotateX(0deg) translateX(0px) translateY(-10px);
    transform:  rotateX(0deg) translateX(0px) translateY(-10px);
  }
  .fade-leave-to {
    opacity: 0;
    -webkit-transform: rotateX(0deg) translateX(0px) translateY(20px);
    -moz-transform: rotateX(0deg) translateX(0px) translateY(20px);
    -ms-transform: rotateX(0deg) translateX(0px) translateY(20px);
    -o-transform: rotateX(0deg) translateX(0px) translateY(20px);
    transform:  rotateX(0deg) translateX(0px) translateY(20px);
  }

  .fade-enter-to {
    opacity: 1;
  }
</style>
