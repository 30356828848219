export const Default = {
    data: function () {
        return {
            messageVisibility: false,
        }
    },
    methods: {
        initialize: function () {
            this.setWindowTop();
        },
        setWindowTop: function () {
            window.scrollTo(0,0);
        },
        setPostSlug: function () {
             this.slug = slugify(this.title,{
                 replacement: '-',              // replace spaces with replacement character, defaults to `-`
                 remove: /[*+~.()'"=?!|:@]/g,   // remove characters that match regex, defaults to `undefined`
                 lower: true,                   // convert to lower case, defaults to `false`
                 strict: false,                 // strip special characters except replacement, defaults to `false`
                 locale: 'en',                  // language code of the locale to use
             })
        },
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        }
    },
}
