<template>
  <div class="partial-copyright">
    <div class="legal">
      <router-link to="/terms-of-use">Terms of Use</router-link>
      <span>—</span>
      <router-link to="/privacy-policy">Privacy Policy</router-link>
    </div>
    <div class="copyright">
      <span>© {{ year }} avenuez. All rights reserved<router-link to="/login">.</router-link></span>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Copyright",
    data: function () {
      return {
        year: new Date().getFullYear()
      }
    },
    methods: {
    }
  }
</script>

<style lang="scss" scoped>
.partial-copyright {
  .copyright {
    padding-right: 7px;
    line-height: 18px;

    span {
      font-size: 12px;
      color: #babfc4;

      a {
        text-decoration: none;
      }
    }
    @media screen and (max-width: 767px) {
      padding-right: 0;
      margin-top: 20px;
    }
    @media screen and (max-width: 575px) {
      margin-top: 40px;
    }
  }

  .legal {
    padding-right: 8px;
    line-height: 18px;

    a {
      font-size: 12px;
      text-decoration: none;
      color: #babfc4;

      &:hover {
        color: #00B0FF;
      }
    }
    span {
      font-size: 13px;
      color: #babfc4;
      padding: 0 5px;
    }
  }
}
</style>
