import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDwBa8pFYH46qQX_JU432UQ0fx8QHtUnEk",
  authDomain: "avenuez-6eaf2.firebaseapp.com",
  projectId: "avenuez-6eaf2",
  storageBucket: "avenuez-6eaf2.appspot.com",
  messagingSenderId: "536585468426",
  appId: "1:536585468426:web:6b6ff4054c1359d7f8bdf3"
};

firebase.initializeApp(firebaseConfig);


import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
//toast notification options
};

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// Vue meta tags
// import { createMetaManager } from 'vue-meta'
import {
  createMetaManager,
  defaultConfig,
} from 'vue-meta';

//...

const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: 'meta', nameless: true },
});


createApp(App)
  .use(store)
  .use(router)
  .use(ElementPlus)
  .use(Toast, options)
  .use(metaManager)
  // .use(createMetaManager())
  .mount('#app')
