<template>
  <div class="partial-socials">
<!--          <a href="#" title="Facebook">
            <i class="fa fa-facebook"></i>
          </a>-->
          <a href="https://instagram.com/zugakeys/" title="Instagram">
            <i class="fa fa-instagram"></i>
          </a>
    <a href="https://www.linkedin.com/in/dominik-zagmester" target="_blank" title="LinkedIn">
      <i class="fa fa-linkedin"></i>
    </a>
    <!--      <a href="#" title="Youtube">
            <i class="fa fa-youtube-play"></i>
          </a>-->
<!--          <a href="#" title="Twitter">
            <i class="fa fa-twitter"></i>
          </a>-->
    <a href="mailto:info@avenuez.me" title="Mail">
      <i class="fa fa-envelope-o"></i>
    </a>
<!--    <a href="#" title="Search this site">
      <i class="fa fa-search"></i>
    </a>-->
  </div>
</template>

<script>
  // Logic
  export default {
    name: 'SocialMediaLinks',
  }
</script>
