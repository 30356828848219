<template>
  <div class="partial-navigation">


    <div class="logo">
<!--      <img src="@/assets/images/avenuez-badge.svg" alt="avenuez badge" class="logo-badge">-->
      <router-link to="/">avenuez</router-link>
    </div>

    <nav class="navigation">
      <router-link class="nav-link" to="/">Home</router-link>
<!--      <router-link class="nav-link" to="/about">About</router-link>-->
      <router-link class="nav-link" to="/projects">Projects</router-link>
      <router-link class="nav-link" to="/contact">Contact</router-link>
<!--      <router-link class="nav-link" to="/login">Login</router-link>-->
<!--      <router-link class="nav-link" to="/register">Register</router-link>-->
      <router-link v-if="this.isLoggedIn" class="nav-link" to="/dashboard">Dashboard</router-link>
      <router-link v-if="this.isLoggedIn" class="nav-link" to="/code-generator">Code Gen.</router-link>
      <a v-if="this.isLoggedIn" class="nav-link" @click="logout" >Logout</a>
    </nav>

    <!-- Socials -->
    <SocialMediaLinks/>



    <!-- End Socials -->

    <Copyright/>


  </div>
</template>

<script>
  /* Mixins */
  import { Default } from '@/mixins/default';
  import Copyright from "@/components/Partials/Copyright";
  import SocialMediaLinks from "@/components/Partials/SocialMediaLinks";
  import {getAuth, signOut} from "firebase/auth";
  import { useToast } from "vue-toastification";
  import firebase from "firebase/compat/app";
  const toast = useToast()

  /* Logic */
  export default {
    name: 'Navigation',
    components: {
      Copyright,
      SocialMediaLinks
    },
    mixins: [Default],
    data() {
      return {
        isLoggedIn: false,
      }
    },
    methods: {
      async logout() {
        try {
          console.log('logout SAD')
          const auth = getAuth();
          await signOut(auth);
          toast.success("Logout successful!");
          this.$router.push('/'); // Redirect the user to the login or home page after logout
        } catch (error) {
          // Handle any errors that may occur during logout
          toast.error("An error occurred during logout. Please try again.");
        }
      }
    },
    created: function () {
      firebase.auth().onAuthStateChanged(user => {
        this.isLoggedIn = !!user;
      });
    },
  }
</script>
<style lang="scss" scoped>
.partial-copyright {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.partial-navigation {
  .logo {
    a {
      &:before {
        content: '';
        background: url('../../assets/images/avenuez-badge.svg') no-repeat;
        background-size: 22px;
        background-position: bottom;
        width: 24px;
        height: 32px;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .logo-badge {
      width: 22px;
      height: auto;
      vertical-align: top;
      margin-right: 10px;
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
    }
  }
}
</style>
